import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import parse from "html-react-parser"

const CareersPage = ({ data }) => {
  return (
    <Layout isOverflowHidden='scrollable'>
      <div className="container boxed-container container_ohidden">
        <div>
          <h1 className="projects_title text-align-center">{data.wpPage.title}</h1>
          {parse(data.wpPage.content)}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    wpPage(title: { eq: "Careers" }) {
      id
      title
      content
    }
  }
`


export default CareersPage
